import I18n from "~/i18n";
import base from "../base";
import logo from "./logo.svg";
import backgroundImage from "./background.jpg";
import favicon16 from "./favicon-16x16.png";
import favicon32 from "./favicon-32x32.png";
import favicon96 from "./favicon-96x96.png";
import { BrandInterface } from "../interface";

const ikon = {
  ...base,
  title: "Ikon Pass",
  logo,
  backgroundImage,
  fontFamily: "futura-pt, sans-serif;",
  resortURL: () => `https://ikonpass.com/${I18n.locale}`, // We need to support locales
  enabled: {
    ...base.enabled,
    logoLockup: false,
  },
  favicons: {
    "16x16": favicon16,
    "32x32": favicon32,
    "96x96": favicon96,
  },
  color: {
    ...base.color,
    textColor: "#092340",
    textColorAlt: "#ffffff",
    header: "rgba(5, 21, 38, 0.8)",
    footer: "transparent",
    footerText: "#ffffff",
    button: "#007db7",
    buttonHover: "#006695",
    input: "#575757",
    inputBorder: "#757575",
    inputFocus: "#007DB7",
    inputError: "#EA1609",
    inputErrorText: "#D91208",
    inputLabel: "#092340",
    inputLabelError: "#D91208",
    inputBackground: "#fafafa",
    error: "#D91208",
  },
} as BrandInterface;

export default ikon;
