import base from "../base";
import resortBase from "../base/resort";
import logo from "./logo.svg";
import backgroundImage from "./background.jpg";
import favicon16 from "./favicon-16x16.png";
import favicon32 from "./favicon-32x32.png";
import favicon96 from "./favicon-96x96.png";
import { BrandInterface } from "../interface";

const mammoth = {
  ...base,
  ...resortBase,
  title: "Crystal Mountain Resort",
  logo,
  backgroundImage,
  resortURL: "https://www.crystalmountainresort.com",
  favicons: {
    "16x16": favicon16,
    "32x32": favicon32,
    "96x96": favicon96,
  },
  color: {
    ...base.color,
    ...resortBase.color,
    header: "#072B38",
    footer: "#072B38",
    footerText: "#ffffff",
    button: "#B13710",
    buttonHover: "#8E2C0D",
  },
} as BrandInterface;

export default mammoth;
