import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";

import { DATADOG_RUM_CONFIG } from "./config";

// This component initializes and manages the Datadog Real User Monitoring (RUM) functionality
export default function DatadogRum() {
  useEffect(() => {
    datadogRum.init(DATADOG_RUM_CONFIG);
    datadogRum.setGlobalContextProperty("team", "egi");
    datadogRum.startSessionReplayRecording();
  }, []);

  return null;
}
