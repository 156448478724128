import { css } from "styled-components";
import { BrandInterface } from "../interface";
import base from "./index";

const resortBase = {
  ...base,
  fontFamily: "proxima-nova, sans-serif",
  color: {
    ...base.color,
    textColor: "#252525",
    textColorAlt: "#ffffff",
    header: "#ffffff",
    buttonText: "#ffffff",
    input: "#FFFFFF #FFFFFF #63666A #FFFFFF",
    inputFocus: "#252525",
    inputError: "#FFFFFF #FFFFFF #EA1609 #FFFFFF",
    inputErrorText: "#D91208",
    inputLabel: "#252525",
    inputLabelError: "#D91208",
    inputBackground: "#f4f4f4",
    error: "#D91208",
  },
} as BrandInterface;

export default resortBase;
