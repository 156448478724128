// This file is automatically generated by the "mapping" process

interface Mapping {
  [clientId: string]: string;
}

const mapping = {
  e2sXme9vMr0HgGtbtpiuwYZiFkltXSZI: "str",
  "7nziY8TANYsXmwyRwevXocvXNU1J05ka": "prototype",
  pLiF2PsDdadBFZgFp0nYRvfDEf5KaJjp: "sug",
  Nyn72qi6Ip8k6MY7GvIe0eN4GhovaFoF: "sb",
  n9Z7J21nTBf1oQg2xT6SVONKuhmpfUoF: "crm",
  j3CjjzxqzcTOssyKjlkCzbd9ex11PdzC: "ikon",
  TQvsnjiaqHDdj99cZhWjdGcijvILEYKZ: "sno",
  Kiu3plzElw2kKpjRw9PQBRJwq5sFA8aR: "sol",
  Oio1gMcJCT5izG2ikxoZO4tAf8P0GBro: "str",
  Jl9JPA3HuXe5koEWFVPZp39W1JfNLTne: "wp",
  U7ovZU0zqRyO8aeDYLEczNHXEFNYEZOk: "sb",
  STHXJqcOZgGhHzxY1KLJcQlpeE11NbXb: "sqa",
  QaAzvoFLIlRHHU5Ykhjt8fRfcwzXVtiZ: "qa",
  iWdDjVuPEsjB8zEdOD4X9jN7LpN3mIgL: "ikon",
  CAIL57HTIzkLF67s4s8OOiWUx9SxSqQ4: "szm",
  oCi0FQbD4yIXeugFYN7OsAwg0rAH403V: "ikon",
  "1KAFjqEMHy2AN4pdeXMR4FXjuJWvFNnA": "sol",
  vtevdwWwLIZJtqA4p7ckXfwplFG5CZtT: "sol",
  FaotlbXattvNdgN4XE0h3FggsO4d8G0y: "mam",
  "6JqjIiidS8FwHU6XzV5hXd6wC3I0Xz0s": "mam",
  iU8d1g8Rk4sdnNOKCQKZeFL3M69ZEUb0: "crm",
  "3Ejbfhpj3gvdgXePQKX8incYUFMZmoAP": "bbm",
  ZTSR63dhaMFw1hxDgH0CHXm3J2rG7kNH: "blm",
  aL0y2bG1qPc6vk6l9p1firLJT5Y3yOM8: "f&b",
  tqo3SCOKX3IVDn358cKO7o76LrlwWnps: "dvr",
  njUq2gJvza9G0CKBR8GKQrR2LR7ZLvLO: "mam",
  GGbudVAZ764PcGJx17vekE8mm2xC9TKu: "jun",
  SD0rhPSzhKws1SwH5okWxz08DamLnjI6: "sb",
  "12abrxzW7QjWZGuLobL845vYZmwfLmgy": "sno",
  ifE2bMRS10dQfhkVlpz9qBpskb0OkZoI: "sol",
  pApPjPsuwiZkYaXjekfc5V8NwlmiFLFB: "sqa",
  noZYAxX0G7zjp9x8eixjLNOPjLtFnLHb: "str",
  oL5jGQ9uMJxTtJvhJOSH4KptMGImWYXs: "sug",
  o6XnG53vPuq0g7FiHqMrUsqJVuuBvOOs: "trb",
  gntUzV7wvdzif7oVVr59IhpTkI1VabOb: "wp",
  aDoe0lTDf2VhcDxdZeA7wdSm6pBDcsJi: "sno",
  gI81JgmvBPa5GPDeDB3zcipg215Du8R0: "ikon",
  LfzpnVqsAOJs2Luj8i50bcJadBolPWkV: "trb",
  qk7uLquB6vRuhPKLMCDBljsscDn8v6Bp: "bbm",
  YRwJWihafOP02XyregPHWLrbvwPjMjEf: "blm",
  o233uEx7ztKxC1nH06CVZN2QmH99PiYV: "dvr",
  "9YEczjTwjdtELn3BP072nI8P0kyfV6x9": "crm",
  lUXZFcAmUsmjxgVOrY5Xl2tfZtezWMlN: "jun",
  AyNzsGy3MZdD0Q0WNzNXQDNYaQba1WHL: "sb",
  fJgjKhV5nEhQD39iIakk2riPeiJIjqNh: "sno",
  kTysx6YnLeJiRoHf0InAmRYx4s0bMkzG: "sqa",
  "7V46ZhrhILJ3BrlRpUwrxddCXmGsdhgC": "sug",
  DmkQgyw5Rmta7uHZUrzbv266kYjfIqod: "str",
  gEy5nBbzMOgYkBdGWHihweaQfcDDQgFZ: "trb",
  AGNrZ77Y02BpGDwguuhffVKKUOeQu8vJ: "wp",
  VSkInDCaKPHu5Vp2ITQ33VdEKVjJ3eUJ: "ikon",
  aRJFxTF1xOL5TDx8SfItsTCaTUPUoSvB: "wp",
  bNgcqznRZH4SJCVCL7669hJlEIUWU4lM: "sb",
  FKhOBJYuK83cALLHGGNrPutH9YTu5NO3: "trb",
  BtbUJXfRVAOOrl8Uh9sOidg15V0RY9a6: "ikon",
  Mqn0BxZGNFWcm2G91FAdReAk9fENzCWr: "ikon",
  E2J3LSk6UASdGsXABQq5GMML0SaYpLc0: "ikon",
  BcPTlZgwGbf3SmyR7NW7Z7z6Mc2fU1LE: "ikon",
  f8Xgz2xHwjmiZKILdUxdXEK0Wjd91ZtE: "ikon",
  F9rRGxvGDFIsxBZeHE6UMIHONq4FANAb: "ikon",
  CVfNDfHGIlFUw2YX0VHjyer0xgNoAUFo: "ikon",
  KH2iFlFiI54MmUzySD0HURr2No4UV5bh: "ikon",
  "312cfhYa62OdxF0swcbeuofMeUO1rEHR": "ikon",
  K4iQGPZmy28SOkKfbA2nMj7CkJwwqg8f: "ikon",
  SpuSaPB5SLDUmJ3Rq0746MbM3jIagtOQ: "ikon",
  XssM2Dt2wotXlSzmxqzYICthMmTtNsUK: "ikon",
  hbCtQ5Z7wNbNzY6eURG5wohRIYWm9tit: "ikon",
  WWyQwYEj5Y1MKfHXFzjZKFomJCVNd3h5: "ikon",
  B4CwaQjD9uTYyx6V0sTjhejC9ICBMxfp: "ikon",
  F7OU2FnHSAe7zG6ciZBxCNLazuz4K22U: "ikon",
  C3bdaFls4lZ0dTfAxbxaQYTeBomTQSoP: "ikon",
  Gs3nm7GYjc3sdN3Laj5Bner3xFf4irT7: "ikon",
  mWY1hDjtr8tRcOY3Y0eI3fyDii6pwXHo: "dvr",
  "1lpD8YNEzdQYyGUdoU4jvWNfvuZGYN5I": "ikon",
  ZtjlJqIa8AkPdYejV2rYo18U4YUkt2Eb: "ikon",
  NPfNdXrlhu9pkIg9fXXoTOHo4ieCBMag: "ikon",
  w038YcPxPubOnwfgWqsuNRiGua18Mi3R: "ikon",
  "6t9NkCkgDOQArE2hZSYkYYxjaCSTLTx2": "ikon",
  "6FuMYlpSoyn0gzKLTabYtx0jH5g13C13": "ikon",
  Z2Bleoef9vNTkHpAhT73XXf81Vfji0bN: "ikon",
  "2RqZMVb0LVrFwLfAMwP64xXlQoX5uEq2": "ikon",
  iDrULBPLTRViKNSd84sszRo48xN69nEe: "ikon",
  xV5iHMAxLhLBpJLGuFmuoZu6LtSz2fQq: "ikon",
  CcF8KhBjmjRlM0zCE1tIpqePg1BNYklx: "ikon",
  ypJ9EPhHmp3WrHuQi66Yf9EJ5wvjadgx: "ikon",
  Alxn2u3GlgLMAZ0vGfibGxAF9x4caFYH: "ikon",
  WMsRbJDLGIDHMwlGKtSuN7vSI1QLXtff: "ikon",
  PuKBaGWs9VpAx3GLRiqSchOoCWrqwzGg: "ikon",
} as Mapping;

export default mapping;
