import base from "../base";
import resortBase from "../base/resort";
import logo from "./logo.png";
import backgroundImage from "./background.jpg";
import favicon16 from "./favicon-16x16.png";
import favicon32 from "./favicon-32x32.png";
import favicon96 from "./favicon-96x96.png";
import { BrandInterface } from "../interface";

const solitude = {
  ...base,
  ...resortBase,
  title: "Solitude",
  logo,
  backgroundImage,
  resortURL: "https://www.solitudemountain.com",
  favicons: {
    "16x16": favicon16,
    "32x32": favicon32,
    "96x96": favicon96,
  },
  color: {
    ...base.color,
    ...resortBase.color,
    header: "#173963",
    footer: "#173963",
    footerText: "#ffffff",
    button: "#173963",
    buttonHover: "#122E4F",
  },
} as BrandInterface;

export default solitude;
