import base from "../base";
import backgroundImage from "./background.jpg";
import { BrandInterface } from "../interface";

const foodAndBeverage = {
  ...base,
  title: "Food and Beverage",
  backgroundImage,
  enabled: {
    ...base.enabled,
    header: false,
  },
  resortURL: "",
  color: {
    ...base.color,
    footer: "#092340",
    footerText: "#ffffff",
    button: "#007DB7",
    buttonText: "#ffffff",
    buttonDisabled: "#007DB7",
    link: "#007DB7",
  },
} as BrandInterface;

export default foodAndBeverage;
