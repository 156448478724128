// This file is automatically generated by the "mapping" process

interface Mapping {
  [clientId: string]: string;
}

const mapping = {
  e2sXme9vMr0HgGtbtpiuwYZiFkltXSZI: "https://account.ikonpass.com/login",
  "7nziY8TANYsXmwyRwevXocvXNU1J05ka": "https://account.ikonpass.com/login",
  pLiF2PsDdadBFZgFp0nYRvfDEf5KaJjp: "https://shop.sugarbush.com/login",
  Nyn72qi6Ip8k6MY7GvIe0eN4GhovaFoF: "https://shop.steamboat.com/login",
  j3CjjzxqzcTOssyKjlkCzbd9ex11PdzC: "https://account.ikonpass.com/login",
  TQvsnjiaqHDdj99cZhWjdGcijvILEYKZ: "https://shop.snowshoemtn.com/login",
  Kiu3plzElw2kKpjRw9PQBRJwq5sFA8aR: "https://shop.solitudemountain.com/login",
  Oio1gMcJCT5izG2ikxoZO4tAf8P0GBro: "https://shop.stratton.com/login",
  Jl9JPA3HuXe5koEWFVPZp39W1JfNLTne: "https://shop.winterparkresort.com/login",
  U7ovZU0zqRyO8aeDYLEczNHXEFNYEZOk: "https://shop.steamboat.com/login",
  STHXJqcOZgGhHzxY1KLJcQlpeE11NbXb: "https://shop.palisadestahoe.com/login",
  QaAzvoFLIlRHHU5Ykhjt8fRfcwzXVtiZ: "https://account.ikonpass.com/login",
  oCi0FQbD4yIXeugFYN7OsAwg0rAH403V: "https://account.ikonpass.com/login",
  aRJFxTF1xOL5TDx8SfItsTCaTUPUoSvB: "https://account.ikonpass.com/login",
} as Mapping;

export default mapping;
