import { BrandInterface } from "../interface";
import favicon16 from "./favicon-16x16.png";
import favicon32 from "./favicon-32x32.png";
import favicon96 from "./favicon-96x96.png";

const base = {
  enabled: {
    header: true,
    logoLockup: true,
    facebook: true,
    apple: true,
    passwordless: true,
    createAccount: true,
    forgotPassword: true,
    google: true,
  },
  title: "None",
  logo: "",
  resortURL: "",
  fontFamily: "proxima-nova, sans-serif",
  favicons: {
    "16x16": favicon16,
    "32x32": favicon32,
    "96x96": favicon96,
  },
  color: {
    textColor: "",
    background: "",
    passwordTooShort: "#EB0A08",
    passwordWeak: "#EFA406",
    passwordOK: "#F7FF03",
    passwordGood: "green",
    buttonText: "#ffffff",
    buttonDisabled: "#EDEDED",
    buttonDisabledText: "#C2C2C2",
  },
  device: {
    notMobile: "only screen and (min-width: 1023px)",
    smallMobile: "screen and (max-width: 320px)",
  },
} as BrandInterface;

export default base;
