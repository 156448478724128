import { BRAND } from "../config";

import { ThemesInterface } from "./interface";
import base from "./base";
import sol from "./solitude";
import mam from "./mammoth";
import wp from "./winterpark";
import sb from "./steamboat";
import dvr from "./deervalley";
import crm from "./crystal";
import sqa from "./palisades-tahoe";
import str from "./stratton";
import bbm from "./big-bear";
import sug from "./sugarbush";
import blm from "./blue-mountain";
import sno from "./snowshoe";
import trb from "./tremblant";
import jun from "./june";
import fnb from "./food-and-beverage";
import ikon from "./ikon";
import szm from "./schweitzer";

export const themes = {
  base,
  sol,
  mam,
  wp,
  sb,
  dvr,
  crm,
  sqa,
  str,
  bbm,
  sug,
  blm,
  sno,
  trb,
  jun,
  "f&b": fnb,
  ikon,
  szm,
} as ThemesInterface;

export const getTheme = (brand: string) => themes[brand] || base;
export const theme = getTheme(BRAND);
