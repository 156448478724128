import { theme } from "../branding/themes";

const setFavicons = () => {
  const favicons = theme.favicons || {};
  Object.entries(favicons).forEach(([sizes, href]) => {
    const favicon = document.createElement("link");
    favicon.setAttribute("type", "image/png");
    favicon.setAttribute("rel", "icon");
    favicon.setAttribute("sizes", sizes);
    favicon.setAttribute("href", href);
    document.head.appendChild(favicon);
  });
};

export default setFavicons;
