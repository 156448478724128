import base from "../base";
import resortBase from "../base/resort";
import logo from "./logo.svg";
import backgroundImage from "./background.jpg";
import favicon16 from "./favicon-16x16.png";
import favicon32 from "./favicon-32x32.png";
import favicon96 from "./favicon-96x96.png";
import { BrandInterface } from "../interface";

const snowshoe = {
  ...base,
  ...resortBase,
  title: "Snowshoe",
  logo,
  backgroundImage,
  resortURL: "https://www.snowshoemtn.com/",
  favicons: {
    "16x16": favicon16,
    "32x32": favicon32,
    "96x96": favicon96,
  },
  color: {
    ...base.color,
    ...resortBase.color,
    footer: "#191919",
    footerText: "#ffffff",
    button: "#0064A4",
    buttonHover: "#005083",
  },
} as BrandInterface;

export default snowshoe;
